import News from '@/models/News'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    newsDetails: null,
    totalNews: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setNewsList(state, news) {
      state.list = news
    },

    clearNewsList(state) {
      state.list = []
      state.totalNews = 0
    },

    setNewsListMeta(state, meta) {
      state.listMeta = meta
    },

    setNewsDetails(state, news) {
      if (state.newsDetails) {
        if (!news.banner) news.banner = state.newsDetails.banner
        if (!news.images) news.images = state.newsDetails.images
      }

      if (news instanceof News) {
        state.newsDetails = news
      } else {
        state.newsDetails = new News(news)
      }
    },

    setNewsImage(state, payload) {
      if (state.newsDetails) {
        state.newsDetails[payload.image_name] =
          payload.images && payload.image_name === 'banner'
            ? payload.images[0]
            : [...state.newsDetails.images, ...payload.images]
      }
    },

    clearNewsDetails(state) {
      state.newsDetails = null
    },

    setTotalNews(state, total) {
      state.totalNews = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getNews({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = News.page(params.page || 1)
          .limit(params.itemsPerPage || 10)
          .include(['user', 'buildings'])

        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }
        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        switch (params.filter) {
          case 'active':
            query.where('is_active', 1)
            break
          case 'inactive':
            query.where('is_active', 0)
            break
          case 'scheduled':
            query.where('publish_date', 'not_publish')
            break
          case 'all':
            query.where('publish_date', 'all')
            break
          default:
            break
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        res = await query.get()
        commit('setNewsList', res.data)
        commit('setNewsListMeta', res.meta)
        if (res.meta.total > 0) {
          commit('setTotalNews', res.meta.total)
        }
      } else {
        res = await News.params({ paginate: false }).get()
        return res.data
      }
    },

    getNewsByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getNewsDetails({ commit }, id) {
      const { data } = await Api.get(`news/${id}`)
      commit('setNewsDetails', data.data)
    },

    async createNews({ commit }, formData) {
      const { data } = await Api.post(`news`, formData)

      const news = data.data
      commit('setNewsDetails', news)
    },

    async updateNews({ commit }, formData) {
      const { data } = await Api.put(`news/${formData.id}`, formData)

      const news = data.data
      commit('setNewsDetails', news)
    },

    async uploadNewsImage({ commit }, formData) {
      const { data } = await Api.post(
        `news/${formData.get('id')}/images`,
        formData
      )

      commit('setNewsImage', {
        images: data.data,
        image_name: formData.get('image_name'),
      })
    },

    async deleteNewsImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)

      if (formData.image_name === 'banner') {
        commit('setNewsImage', {
          images: [null],
          image_name: formData.image_name,
        })
      }
    },

    async exportToExcel({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        sort: params.sort || '',
      })

      switch (params.filter) {
        case 'active':
          queryParams.append('filter[is_active]', 1)
          break
        case 'inactive':
          queryParams.append('filter[is_active]', 0)
          break
        default:
          break
      }

      if (params.search) {
        queryParams.append('filter[search]', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]', filteredBuilding)
      }

      const file = await Api.get(`/news/export?${queryParams.toString()}`, {
        responseType: 'blob',
      })
      return file
    },
  },
}
