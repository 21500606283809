import Bookable from '@/models/Bookable'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    bookableDetails: null,
    totalBookables: 0,
    queryParams: null,
    scrollPosition: 0,
    blockTimeSlotList: [],
    blockTimeSlotListMeta: {
      current_pageL: 0,
      last_page: 1,
      total: 0,
    },
    listBookParams: null,
  },

  mutations: {
    setBookableList(state, bookables) {
      state.list = bookables
    },

    clearBookableList(state) {
      state.list = []
      state.totalBookables = 0
    },

    setBookableListMeta(state, meta) {
      state.listMeta = meta
    },

    setBookableDetails(state, bookable) {
      if (state.bookableDetails) {
        if (!bookable.media || !bookable.media.length)
          bookable.media = state.bookableDetails.media
      }

      if (bookable instanceof Bookable) {
        state.bookableDetails = bookable
      } else {
        state.bookableDetails = new Bookable(bookable)
      }
    },

    setTotalBookables(state, total) {
      state.totalBookables = total
    },

    setBookableImage(state, payload) {
      if (state.bookableDetails) {
        const existing = Array.isArray(state.bookableDetails.media)
          ? state.bookableDetails.media
          : []

        state.bookableDetails.media = [...existing, ...payload]
      }
    },

    clearBookableDetails(state) {
      state.bookableDetails = null
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setListBookParams(state, params) {
      state.listBookParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },

    setBlockTimeSlotList(state, list) {
      state.blockTimeSlotList = Array.isArray(list) ? [...list] : []
    },

    addBlockTimeSlot(state, blockTimeSlot) {
      state.blockTimeSlotList = [...state.blockTimeSlotList, blockTimeSlot]
    },

    setBlockTimeSlotListMeta(state, meta) {
      state.blockTimeSlotListMeta = meta
    },
  },

  actions: {
    async getBookables({ commit, rootGetters }, params) {
      if (params.paginate) {
        const query = Bookable.page(params.page || 1).orderBy(params.sort)

        switch (params.filter) {
          case 'all':
            break
          default:
            query.where('booking_option', params.filter)
            break
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['building'])

        const res = await query
          .params({ limit: params.itemsPerPage || 10 })
          .get()

        commit('setBookableList', res.data)
        commit('setBookableListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setTotalBookables', get(res, 'meta.total', 0))
        }
      } else {
        const query = Bookable.params({ paginate: false })

        if (params.buildingId) {
          query.where('building_id', params.buildingId)
        }
        query.include(['building'])

        const res = await query.get()

        return res.data
      }
    },

    async getBookableDetails({ commit }, id) {
      const { data } = await Api.get(`bookables/${id}?include=media`)
      commit('setBookableDetails', data.data)
    },

    async createBookable({ commit }, formData) {
      const { data } = await Api.post(`bookables`, formData)
      commit('setBookableDetails', data.data)
    },

    async updateBookable({ commit }, formData) {
      const { data } = await Api.put(`bookables/${formData.id}`, formData)
      commit('setBookableDetails', data.data)
      return data
    },

    async uploadBookableImages({ commit }, formData) {
      const { data } = await Api.post(
        `bookables/${formData.get('id')}/images`,
        formData
      )

      commit('setBookableImage', data.data)
    },

    async deleteBookableImage({ commit }, formData) {
      await Api.delete(`bookables/${formData.id}/images/${formData.image}`)
    },

    async createBlockTimeSlot({ commit }, blockFormData) {
      const { data } = await Api.post(`block-time-slots`, blockFormData)
      commit('addBlockTimeSlot', data.data)
      return data
    },

    async getBlockTimeSlotList({ commit }, param) {
      const { data } = await Api.get(
        `block-time-slots?filter[bookable_id]=${param.id}&per_page=10&page=${param.page}&filter[past_slots]=${param.showAllSlotsType}`
      )
      commit('setBlockTimeSlotListMeta', data.meta)
      commit('setBlockTimeSlotList', data.data)
    },

    async updateBlockTimeSlotItem({ commit }, formData) {
      const { data } = await Api.put(
        `block-time-slots/${formData.id}`,
        formData
      )
      commit('setBlockTimeSlotList', data.data)
    },

    async deleteBlockTimeSlotItem({ commit }, id) {
      await Api.delete(`block-time-slots/${id}`)
    },
  },
}
